import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import VideoCard from "../components/video-card"

const VideoPage = () => {
  const data = useStaticQuery(graphql`
    {
      allVimeoVideo {
        nodes {
          title
          thumbnail
          duration
          id
          date
        }
      }
    }
  `)
  // Array of all news videos
  const allVideos = data.allVimeoVideo.nodes

  // State for the list
  const [list, setList] = useState([...allVideos.slice(0, 10)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allVideos.length > 10)

  const loadRef = useRef()

  // Handle intersection with load more div
  const handleObserver = entities => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more videos
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allVideos.length
      const nextResults = isMore
        ? allVideos.slice(currentLength, currentLength + 10)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allVideos.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <Layout>
      <div className="mt-10">
        <div className="text-4xl font-black mb-10 full-width px-10 md:px-20 lg:px-24 xl:px-40 py-10 bg-hctv-green flex">
          <h2 className="hctv_underline text-white">Video Library</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {list.map(video => (
            <VideoCard
              thumbnail={video.thumbnail}
              title={video.title}
              link={"/" + video.id}
              time={video.duration}
              date={video.date}
            />
          ))}
        </div>
        <div ref={loadRef} className="flex justify-center">
          {hasMore ? <p>Loading...</p> : <p>No more results</p>}
        </div>
      </div>
    </Layout>
  )
}

export default VideoPage
